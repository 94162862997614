import React from 'react'
import PageTransition from 'gatsby-plugin-page-transitions'

import Layout from "../global/layout"
import SEO from "../global/seo"

import HeroOne from '../components/hero-one'
import Heading from '../components/heading'

const AboutPage = () => {
    return (
        <PageTransition>
            <Layout>
            
                <SEO title="about us" />

                <HeroOne />

                <Heading>about us</Heading>


            </Layout>
        </PageTransition>
    )
}

export default AboutPage