import React from 'react'

import CSS from './hero-one.module.css'

const HeroOne = props => {

    const handleInverse = () => props.inverse ? CSS.inverse : null

    return (
        <div className={`${CSS.wrapper} ${handleInverse()}`}>
            <h1 className={CSS.one}>one</h1>
        </div>
    )
}

export default HeroOne