import React from 'react'

import CSS from './heading.module.css'

const Heading = props => {


    const handleFlipped = () => props.flipped ? CSS.flipped : null
    const handleSize = () => props.large ? CSS.large : null

    return (
        <div className={`${CSS.wrapper} ${handleSize()}`}>
            <h2 className={`${CSS.heading} ${handleFlipped()} ${handleSize()}`}>
                {props.children} {props.size}
            </h2>
        </div>
    )
}

export default Heading